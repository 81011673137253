import {AjaxHelper} from "ajax_helper";
import {DsRoute} from "~/helper/DsRoute";
import {onUnmounted, ref} from "vue";

export function useSearchProduct(){
    const is_searching = ref(false);
    async function searchProduct(query:string|string[]|null):Promise<{search_result_locker: any[]; search_result_epal: any[]}>{
        if(!query){
            return {
                search_result_locker: [],
                search_result_epal: []
            };
        }
        if (is_searching.value) {
            return {
                search_result_locker: [],
                search_result_epal: []
            };
        }
        is_searching.value = true;
        let search_result_locker = [];
        let search_result_epal = [];
        try {
            const response = await AjaxHelper.post(DsRoute.route('ajax.logistics.stock.product-search'), {
                query: query,
            });

            if (!response.isSuccess()) {
                throw new Error(response.getMessage() ?? 'Une erreur est survenue lors de la recherche du produit. Veuillez réessayer plus tard.');
            }
            search_result_locker = response.getData('extra_data.products_locker') ?? [];
            search_result_epal = response.getData('extra_data.products_epal') ?? [];
        } catch (e) {
            console.error(e);
            alert(e.message);
        } finally {
            is_searching.value = false;
        }

        return {
            search_result_locker:search_result_locker,
            search_result_epal:search_result_epal
        };
    }
    async function searchProductLockerFromSupplier(supplier_id:number,small_data:boolean = false){
        if(!supplier_id){
            return;
        }
        if (is_searching.value) {
            return;
        }
        is_searching.value = true;
        let search_result = [];
        try {
            const response = await AjaxHelper.get(DsRoute.route('ajax.logistics.supplier.get-product-in-locker',{
                supplier_id: supplier_id,
                small_data: small_data
            }));
            if (!response.isSuccess()) {
                throw new Error(response.getMessage() ?? 'Une erreur est survenue lors de la recherche du produit. Veuillez réessayer plus tard.');
            }
            search_result = response.getData('extra_data') ?? [];
        } catch (e) {
            console.error(e);
            alert(e.message);
            return [];
        } finally {
            is_searching.value = false;
        }

        return search_result;
    }

    onUnmounted(() => {
        is_searching.value = false;
    });

    return {
        searchProduct: searchProduct,
        searchProductLockerFromSupplier,
        is_searching
    }
}
